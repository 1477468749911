import { isMetamaskInstalled } from '@qtumproject/qtum-wallet-connector'

import { qtumSnap } from '@/api/clients'
import { createStore } from '@/helpers'

type Web3State = {
  isMetamaskInstalled?: boolean
  isSnapInstalled?: boolean
}

const [web3Store, useWeb3State] = createStore(
  'web3',
  {
    isMetamaskInstalled: false,
    isSnapInstalled: false,
  } as Web3State,
  state => ({
    checkSnapStatus: async () => {
      state.isMetamaskInstalled = await isMetamaskInstalled()
      state.isSnapInstalled = await qtumSnap.isInstalled()

      return {
        isMetamaskInstalled: state.isMetamaskInstalled,
        isSnapInstalled: state.isSnapInstalled,
      }
    },
  }),
  {
    isPersist: false,
  },
)

export { useWeb3State, web3Store }
