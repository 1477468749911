import { Avatar, AvatarProps, useTheme } from '@mui/material'
import jdenticon from 'jdenticon/standalone'
import { useMemo } from 'react'

interface UserAvatarProps extends AvatarProps {
  userAddr: string
  size?: number
}

const UserAvatar = ({ userAddr, size = 12, ...rest }: UserAvatarProps) => {
  const { spacing } = useTheme()

  const userAvatar = useMemo(() => {
    return jdenticon.toSvg(userAddr, parseInt(spacing(size)))
  }, [size, userAddr, spacing])

  return <Avatar {...rest} src={`data:image/svg+xml;utf8,${encodeURIComponent(userAvatar)}`} />
}

export default UserAvatar
