import {
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material'
import { useCallback, useState } from 'react'

import { qtumSnap } from '@/api/clients'
// import { qtumSnap } from '@/api/clients'
import { UserAvatar } from '@/common'
import { BusEvents } from '@/enums'
import { bus, ErrorHandler, formatAddr } from '@/helpers'
import { useAuth, useCopyToClipboard } from '@/hooks'
import { UiIcon, UiIconButton } from '@/ui'

interface ProfileMenuProps {
  userDid: string
  userBTCAddress: string
}

export default function ProfileMenu({ userDid, userBTCAddress }: ProfileMenuProps) {
  const { palette, spacing } = useTheme()
  const { logout } = useAuth()
  const { copy, isCopied } = useCopyToClipboard()
  const { copy: copyBtcAddress, isCopied: isBtcAddressCopied } = useCopyToClipboard()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [isExporting, setIsExporting] = useState(false)

  const menuItemSx: SxProps = { py: 2.5, px: 4 }

  const copyUserAddr = async () => {
    try {
      await copy(userDid)
    } catch (e) {
      bus.emit(BusEvents.error, 'Not copied, please try again')
    }
  }

  const copyUserBtcAddress = async () => {
    try {
      await copyBtcAddress(userBTCAddress)
    } catch (e) {
      bus.emit(BusEvents.error, 'Not copied, please try again')
    }
  }

  const exportWallet = useCallback(async () => {
    try {
      setIsExporting(true)
      await qtumSnap.walletExportPrivateKey(() => {})
    } catch (error) {
      ErrorHandler.process(error)
    }
    setIsExporting(false)
  }, [])

  return (
    <>
      <UiIconButton onClick={event => setAnchorEl(event.currentTarget)}>
        <UserAvatar userAddr={userDid} />
      </UiIconButton>
      <Menu
        anchorEl={anchorEl}
        id='profile-menu'
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        MenuListProps={{
          sx: {
            width: spacing(60),
            border: 1,
            borderColor: palette.action.hover,
            borderRadius: 2,
            pt: 0,
          },
        }}
        slotProps={{ paper: { sx: { background: palette.common.white } } }}
      >
        <Stack direction={'column'} p={4} alignItems={'center'}>
          <UserAvatar sx={{ width: spacing(12), height: spacing(12) }} userAddr={userDid} />
          <Stack direction={'row'} mt={3} spacing={2}>
            <Typography
              variant={'subtitle4'}
              overflow={'hidden'}
              textOverflow={'ellipsis'}
              maxWidth={spacing(30)}
            >
              {formatAddr(userDid)}
            </Typography>
            <UiIconButton onClick={copyUserAddr} color={isCopied ? 'success' : 'primary'}>
              <UiIcon componentName={isCopied ? 'check' : 'contentCopy'} size={4} />
            </UiIconButton>
          </Stack>
          <Stack direction={'row'} mt={3} spacing={2}>
            <Typography
              variant={'subtitle4'}
              overflow={'hidden'}
              textOverflow={'ellipsis'}
              maxWidth={spacing(30)}
            >
              {formatAddr(userBTCAddress)}
            </Typography>
            <UiIconButton
              onClick={copyUserBtcAddress}
              color={isBtcAddressCopied ? 'success' : 'primary'}
            >
              <UiIcon componentName={isBtcAddressCopied ? 'check' : 'contentCopy'} size={4} />
            </UiIconButton>
          </Stack>
        </Stack>
        <Divider sx={{ mb: 2 }} />
        <MenuItem onClick={exportWallet} sx={menuItemSx} disabled={isExporting}>
          <ListItemIcon>
            <UiIcon componentName={'key'} size={5} sx={{ color: palette.text.secondary }} />
          </ListItemIcon>
          <Typography variant={'caption1'}>
            {isExporting ? 'Exporting...' : 'Export Wallet'}
          </Typography>
        </MenuItem>
        <MenuItem onClick={logout} sx={menuItemSx}>
          <ListItemIcon>
            <UiIcon componentName={'logOut'} size={5} sx={{ color: palette.error.main }} />
          </ListItemIcon>
          <Typography variant={'caption1'} color={palette.error.main}>
            Disconnect
          </Typography>
        </MenuItem>
      </Menu>
    </>
  )
}
