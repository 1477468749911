export enum RoutePaths {
  Root = '/',
  UiKit = '/ui-kit',
  SignIn = '/sign-in',

  WalletView = 'view',
  WalletTransactions = 'transactions',
  SafeSend = 'safe-send',
  Send = 'send',
  RequestPayment = 'request-payment',
  DumpAsKeyFile = 'dump-as-key-file',
  CreateToken = 'create-token',
  CreateContract = 'create-contract',
  SendToContract = 'send-to-contract',
  CallContract = 'call-contract',
  CreateNft = 'create-nft',
  Stake = 'stake',
  Delegation = 'delegation',
}
