import { lazy, Suspense, useCallback, useMemo } from 'react'
import {
  createBrowserRouter,
  LoaderFunctionArgs,
  Navigate,
  Outlet,
  redirect,
  RouterProvider,
} from 'react-router-dom'

import { RoutePaths } from '@/enums'
import { useAuth } from '@/hooks'

import { createDeepPath } from './helpers'
import MainLayout from './layouts/MainLayout'
import PublicLayout from './layouts/PublicLayout'

export const AppRoutes = () => {
  const UiKit = lazy(() => import('@/pages/UiKit'))
  const SignIn = lazy(() => import('@/pages/SignIn'))

  const CallContract = lazy(() => import('@/pages/CallContract'))
  const CreateContract = lazy(() => import('@/pages/CreateContract'))
  const CreateNft = lazy(() => import('@/pages/CreateNft'))
  const CreateToken = lazy(() => import('@/pages/CreateToken'))
  const Delegation = lazy(() => import('@/pages/Delegation'))
  const DumpAsKeyFile = lazy(() => import('@/pages/DumpAsKeyFile'))
  const RequestPayment = lazy(() => import('@/pages/RequestPayment'))
  const SafeSend = lazy(() => import('@/pages/SafeSend'))
  const Send = lazy(() => import('@/pages/Send'))
  const SendToContract = lazy(() => import('@/pages/SendToContract'))
  const Stake = lazy(() => import('@/pages/Stake'))
  const WalletView = lazy(() => import('@/pages/WalletView'))
  const WalletTransactions = lazy(() => import('@/pages/WalletTransactions'))

  const { isAuthorized, logout } = useAuth()

  const signInGuard = useCallback(
    ({ request }: LoaderFunctionArgs) => {
      const requestUrl = new URL(request.url)

      const from = requestUrl.searchParams.get('from')

      return isAuthorized ? redirect(from ? `${from}${requestUrl.search}` : RoutePaths.Root) : null
    },
    [isAuthorized],
  )

  const authProtectedGuard = useCallback(
    ({ request }: LoaderFunctionArgs) => {
      // If the user is not logged in and tries to access protected route, we redirect
      // them to sign in with a `from` parameter that allows login to redirect back
      // to this page upon successful authentication
      if (!isAuthorized) {
        logout()

        const requestUrl = new URL(request.url)
        requestUrl.searchParams.set('from', requestUrl.pathname)

        return redirect(`${RoutePaths.SignIn}${requestUrl.search}`)
      }

      return null
    },
    [isAuthorized, logout],
  )

  const LayoutComponent = useMemo(() => {
    return isAuthorized ? MainLayout : PublicLayout
  }, [isAuthorized])

  const router = createBrowserRouter([
    {
      path: RoutePaths.Root,
      element: (
        <LayoutComponent>
          <Suspense fallback={<></>}>
            <Outlet />
          </Suspense>
        </LayoutComponent>
      ),
      children: [
        {
          path: createDeepPath(RoutePaths.UiKit),
          loader: authProtectedGuard,
          element: <UiKit />,
        },
        {
          path: createDeepPath(RoutePaths.SignIn),
          loader: signInGuard,
          element: <SignIn />,
        },

        {
          path: createDeepPath(RoutePaths.CallContract),
          loader: authProtectedGuard,
          element: <CallContract />,
        },
        {
          path: createDeepPath(RoutePaths.CreateContract),
          loader: authProtectedGuard,
          element: <CreateContract />,
        },
        {
          path: createDeepPath(RoutePaths.CreateNft),
          loader: authProtectedGuard,
          element: <CreateNft />,
        },
        {
          path: createDeepPath(RoutePaths.CreateToken),
          loader: authProtectedGuard,
          element: <CreateToken />,
        },
        {
          path: createDeepPath(RoutePaths.Delegation),
          loader: authProtectedGuard,
          element: <Delegation />,
        },
        {
          path: createDeepPath(RoutePaths.DumpAsKeyFile),
          loader: authProtectedGuard,
          element: <DumpAsKeyFile />,
        },
        {
          path: createDeepPath(RoutePaths.RequestPayment),
          loader: authProtectedGuard,
          element: <RequestPayment />,
        },
        {
          path: createDeepPath(RoutePaths.SafeSend),
          loader: authProtectedGuard,
          element: <SafeSend />,
        },
        {
          path: createDeepPath(RoutePaths.Send),
          loader: authProtectedGuard,
          element: <Send />,
        },
        {
          path: createDeepPath(RoutePaths.SendToContract),
          loader: authProtectedGuard,
          element: <SendToContract />,
        },
        {
          path: createDeepPath(RoutePaths.Stake),
          loader: authProtectedGuard,
          element: <Stake />,
        },
        {
          path: createDeepPath(RoutePaths.WalletView),
          loader: authProtectedGuard,
          element: <WalletView />,
        },
        {
          path: createDeepPath(RoutePaths.WalletTransactions),
          loader: authProtectedGuard,
          element: <WalletTransactions />,
        },

        {
          path: RoutePaths.Root,
          element: <Navigate replace to={RoutePaths.WalletView} />,
        },
        {
          path: '*',
          element: <Navigate replace to={RoutePaths.Root} />,
        },
      ],
    },
  ])

  return <RouterProvider router={router} />
}
