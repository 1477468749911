import isArray from 'lodash/isArray'

import { createStore } from '@/helpers'

type ChainId = string

type Erc20List = {
  contractAddresses: Record<ChainId, string[]>
}

export const [erc20ListStore, useErc20ListState] = createStore(
  'erc20-list_v2',
  {
    contractAddresses: {},
  } as Erc20List,
  state => ({
    addItem: (addr: string, chainId: ChainId) => {
      if (isArray(state.contractAddresses)) {
        state.contractAddresses = {}
      }

      if (!state.contractAddresses[chainId]?.includes?.(addr)) {
        state.contractAddresses[chainId] = [...(state.contractAddresses[chainId] || []), addr]
      }
    },
    removeItem: (addr: string, chainId: ChainId) => {
      const index = state.contractAddresses[chainId]
        .map(el => el.toLowerCase())
        .indexOf(addr.toLowerCase())

      if (index !== -1) {
        state.contractAddresses[chainId].splice(index, 1)
      }
    },
    clear: () => {
      state.contractAddresses = {}
    },
  }),
)
