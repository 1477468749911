import { providers } from 'ethers'
import { useCallback, useMemo } from 'react'

import { qtumSnap } from '@/api/clients'
import { authStore, useAuthState, useWeb3State, web3Store } from '@/store'

// TODO: add jwt validations for specific org
export const useAuth = () => {
  const { address } = useAuthState()
  const { isSnapInstalled } = useWeb3State()

  const isAuthorized = useMemo(
    () => Boolean(address && isSnapInstalled),
    [address, isSnapInstalled],
  )

  const updateAddresses = useCallback(async () => {
    const provider = new providers.Web3Provider(qtumSnap as providers.ExternalProvider, 'any')

    const accounts = await provider.listAccounts()

    if (!accounts.length) return

    await qtumSnap.walletGetAddress((err: Error, resp: string) => {
      if (err) {
        throw err
      }

      authStore.setAuthRequestResponse(accounts[0], resp)
    })
  }, [])

  const connectProviders = useCallback(async () => {
    await qtumSnap.enable()

    await web3Store.checkSnapStatus()

    await updateAddresses()
  }, [updateAddresses])

  const logout = useCallback(async () => {
    authStore.clear()
  }, [])

  return {
    isAuthorized,

    connectProviders,

    updateAddresses,

    logout,
  }
}
