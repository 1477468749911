import { createStore } from '@/helpers'

type AuthState = {
  chainId: string
  address: string
  btcAddress: string
  nativeBalance: string
}

const [authStore, useAuthState] = createStore(
  'auth',
  { address: '', btcAddress: '', nativeBalance: '', chainId: '' } as AuthState,
  state => ({
    setAuthRequestResponse: (address: string, btcAddress: string) => {
      state.address = address || state.address
      state.btcAddress = btcAddress || state.btcAddress
    },
    setBalance: (nativeBalance: string) => {
      state.nativeBalance = nativeBalance
    },
    setChainId: (chainId: string) => {
      state.chainId = chainId
    },

    clear: () => {
      state.address = ''
      state.btcAddress = ''
      state.nativeBalance = ''
      state.chainId = ''
    },
  }),
)

export { authStore, useAuthState }
