import { Divider, Stack, useTheme } from '@mui/material'
import { ReactNode, useCallback, useMemo } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

import { ProfileMenu } from '@/common'
import { Icons, RoutePaths } from '@/enums'
import { uiStore, useAuthState, useUiState } from '@/store'
import { Transitions } from '@/theme/constants'
import { UiIcon, UiIconButton, UiTooltip } from '@/ui'

interface NavbarLinkProps {
  to: RoutePaths
  children: ReactNode
}

const NavbarLink = ({ children, to }: NavbarLinkProps) => {
  const location = useLocation()
  const { palette, spacing } = useTheme()

  const isRouteActive = useMemo(() => {
    return location.pathname.startsWith(to)
  }, [location.pathname, to])

  return (
    <NavLink to={to}>
      <Stack
        alignItems={'center'}
        justifyContent={'center'}
        sx={{
          width: spacing(10),
          height: spacing(10),
          borderRadius: 250,
          backgroundColor: isRouteActive ? palette.additional.pureBlack : 'transparent',
          color: isRouteActive ? palette.primary.main : palette.text.primary,
          transition: Transitions.Default,
          '&:hover': {
            backgroundColor: isRouteActive ? palette.additional.pureBlack : palette.action.hover,
          },
        }}
      >
        {children}
      </Stack>
    </NavLink>
  )
}

const AppNavbar = () => {
  const { palette } = useTheme()
  const { paletteMode } = useUiState()

  const { address, btcAddress } = useAuthState()

  const navbarItems = useMemo(
    () => [
      // {
      //   route: RoutePaths.WalletView,
      //   title: 'View Wallet',
      //   iconComponent: <UiIcon name={Icons.Wallet} size={5} />,
      // },
      // {
      //   route: RoutePaths.WalletTransactions,
      //   title: 'Wallet Transactions',
      //   iconComponent: <UiIcon componentName='list' size={5} />,
      // },
      // {
      //   route: RoutePaths.SafeSend,
      //   title: 'Safe Send',
      //   iconComponent: <UiIcon componentName='shield' size={5} />,
      // },
      // {
      //   route: RoutePaths.Send,
      //   title: 'Send',
      //   iconComponent: <UiIcon componentName='swapHoriz' size={5} />,
      // },
      // {
      //   route: RoutePaths.RequestPayment,
      //   title: 'Request Payment',
      //   iconComponent: <UiIcon componentName='replay' size={5} />,
      // },
      // {
      //   route: RoutePaths.DumpAsKeyFile,
      //   title: 'Dump As Key File',
      //   iconComponent: <UiIcon componentName='cloudDownload' size={5} />,
      // },
      // {
      //   route: RoutePaths.CreateToken,
      //   title: 'Create Token',
      //   iconComponent: <UiIcon componentName='currencyBitcoinOutlined' size={5} />,
      // },
      // {
      //   route: RoutePaths.CreateContract,
      //   title: 'Create Contract',
      //   iconComponent: <UiIcon componentName='edit' size={5} />,
      // },
      // {
      //   route: RoutePaths.SendToContract,
      //   title: 'Send To Contract',
      //   iconComponent: <UiIcon componentName='send' size={5} />,
      // },
      // {
      //   route: RoutePaths.CallContract,
      //   title: 'Call Contract',
      //   iconComponent: <UiIcon componentName='playCircle' size={5} />,
      // },
      // {
      //   route: RoutePaths.CreateNft,
      //   title: 'Create NFT',
      //   iconComponent: <UiIcon componentName='fingerprint' size={5} />,
      // },
      // {
      //   route: RoutePaths.Stake,
      //   title: 'Stake',
      //   iconComponent: <UiIcon componentName='shield' size={5} />,
      // },
      // {
      //   route: RoutePaths.Delegation,
      //   title: 'Delegation',
      //   iconComponent: <UiIcon componentName='shield' size={5} />,
      // },
    ],
    [],
  )

  const togglePaletteMode = useCallback(() => {
    uiStore.setPaletteMode(paletteMode === 'dark' ? 'light' : 'dark')
  }, [paletteMode])

  return (
    <Stack
      justifyContent={'space-between'}
      alignItems={'center'}
      py={6}
      px={4}
      borderRight={1}
      borderColor={palette.divider}
    >
      <Stack spacing={4}>
        <Stack component={NavLink} to={RoutePaths.WalletView} alignItems={'center'}>
          <UiIcon name={Icons.House} size={10} sx={{ color: palette.text.primary }} />
        </Stack>

        <Divider />

        <Stack spacing={4} p={1}>
          {navbarItems.map(({ route, iconComponent, title }, idx) => (
            <NavbarLink to={route} key={idx}>
              <UiTooltip title={title} placement='right'>
                {iconComponent}
              </UiTooltip>
            </NavbarLink>
          ))}
        </Stack>
      </Stack>

      <Stack spacing={4}>
        <UiIconButton onClick={togglePaletteMode}>
          <UiIcon
            componentName={paletteMode === 'dark' ? 'darkModeOutlined' : 'lightModeOutlined'}
            size={5}
          />
        </UiIconButton>
        {address && <ProfileMenu userDid={address} userBTCAddress={btcAddress} />}
      </Stack>
    </Stack>
  )
}

export default AppNavbar
